.chat-header {
  background-color: #5a189a;
  border-radius: 8px 8px 0px 0px;
  padding: 10px;
}

.chat-username {
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.bot-img {
  background-color: #5a189a;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-align: center;
}

.bot-img:hover {
  background-color: #5a189a;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-align: center;
}

li.ant-list-item.chat-list-items {
  border-bottom: none;
}

.user-details.div {
  display: flex;
}

.chat-input {
  margin-bottom: 10px;
  text-align: left;
  background-color: transparent;
  border: none;
}

.chat-input:focus {
  outline: none !important;
  border: none;
}

.down-chat {
  text-align: right;
}

.chat-down-icon {
  color: white;
  font-size: 24px;
  margin-top: 15px;
  cursor: pointer;
}

img.bot-img-style {
  margin-top: 8px;
  height: 35px;
  width: 35px;
}

/* ul.ant-list-items {
  overflow: auto;
  max-height: 300px;
  display: flex;
  flex-direction: column-reverse;
} */

.chat-content {
  padding: 20px;
  overflow: auto;
  max-height: 300px;
  display: flex;
  flex-direction: column-reverse;
}

.user-avatar {
  text-align: right;
}

.message {
  position: relative;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.message.assistant {
  background-color: #3c096c;
  color: white;
}

.message.user {
  background-color: #dee2e6;
}

.message.assistant::after,
.message.user::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border: 10px solid transparent;
}

.message.assistant::after {
  left: 10px;
  border-top-color: #3c096c;
  border-bottom: 0;
  border-right: 0;
  margin-bottom: -10px;
}

.message.user::after {
  right: 10px;
  border-top-color: #dee2e6;
  border-bottom: 0;
  border-left: 0;
  margin-bottom: -10px;
}

.online-status {
  color: #43ee7d;
  font-weight: 600;
  margin-top: -5px;
  margin-bottom: -2px;
}

.chat-logo-style {
  margin-right: 10px;
  margin-top: 5px;
}

.online {
  display: flex;
  margin-top: -15px;
}

.dot-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: 2px;
  background-color: #43ee7d;
}

.send-message-div {
  display: flex;
  align-items: center;
  background-color: #e7ebf1;
  padding: 12px 20px 1px 20px;
  border-radius: 20px;
  bottom: 0;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
}
.send-btn.disabled {
  pointer-events: none;
  opacity: 0.5; /* Change opacity to indicate the disabled state */
}

.chat-input {
  flex: 1;
  margin-right: 10px;
}

.send-btn {
  cursor: pointer;
}

.send-btn-style {
  margin-top: -15px;
  margin-left: 5px;
}

.input-question {
  font-weight: 600;
  text-align: center;
}
.avatar-img {
  width: 50px;
  height: 50px;
}
.timestamp {
  font-size: 0.8em;
  color: gray;
  text-align: right;
  margin-top: 6px;
}
