.custom-select .ant-select-selector {
  background-color: #121c3e !important;
  color: white !important;
  border-radius: 10px 0px 0px 10px !important;
  padding: 9px 0px 0px 15px !important;
  height: 50px !important;
  font-weight: 600 !important;
}
header.ant-layout-header.site-layout-background {
  background: transparent !important;
  display: flex;
}
.custom-select span.ant-select-arrow {
  color: white !important;
}
.search-input-with-select {
  margin-left: 60px;
  display: flex;
  margin-top: 10px;
}

.weekly-goals-module {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
  display: flex;
}
.goals-module-heading {
  font-weight: 600;
  text-align: right;
  width: 100%;
  margin-top: 20px;
}
.weekly-goals-image {
  width: 70px;
  height: 70px;
  object-fit: contain;
}
.weekly-goals-image:hover {
  transform: scale(1.14);
}

.employees-module {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  cursor: pointer;
}
.employees-module-heading {
  font-weight: 600;
  text-align: right;
  width: 100%;

  margin-top: 0px;
}
.employees-image {
  width: 50px;
  object-fit: contain;
  height: 70px;
}
.employees-image:hover {
  transform: scale(1.14);
}
.employees-count-heading {
  font-weight: 600;
  margin-top: 10px;
}
.employees-count {
  width: 100%;
  display: grid;
  margin-top: 6px;
  margin-right: auto;
  text-align: right;
}
.leaves-module {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
  display: flex;
}
.leaves-module-heading {
  font-weight: 600;
  text-align: right;
  width: 100%;
  margin-top: 0px;
}
.leaves-image {
  width: 70px;
  height: 70px;
  object-fit: contain;
}
.leaves-image:hover {
  transform: scale(1.14);
}
.leaves-count-heading {
  font-weight: 600;
  margin-top: 10px;
}
.leaves-count {
  width: 100%;
  display: grid;
  margin-top: 6px;
  margin-right: auto;
  text-align: right;
}
.kpi-module {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
  display: flex;
}
.kpi-module-heading {
  font-weight: 600;
  text-align: right;
  width: 100%;
  margin-top: 20px;
}
.kpi-image {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.kpi-image:hover {
  transform: scale(1.14);
}

.highfives-module {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
  display: flex;
}
.highfives-module-heading {
  font-weight: 600;
  text-align: right;
  width: 100%;
  margin-top: 20px;
}
.highfives-image {
  width: 70px;
  object-fit: contain;
  height: 70px;
}
.highfives-image:hover {
  transform: scale(1.14);
}
.modules-div {
  margin-top: 20px;
}
.weekly-goals-module-style {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}
.dots-div {
  text-align: right;
}
.weekly-goals-heading {
  font-weight: 600;
  font-size: 20px;
}
.weekly-goals-duration {
  font-weight: 600;
  font-size: 18px;
  color: black;
}
.weekly-goal-assignee {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  margin-top: -20px;
}
.menu-icon-style {
  font-size: large;
}
.eye-icon-style {
  font-size: large;
  margin-top: 10px;
  color: white !important;
}
.download-icon-style {
  font-size: large;
  margin-top: 10px;
  color: white !important;
}
p.view-text {
  margin-top: -9px;
  margin-right: 8px;
  color: black;
}
.goal-details-div:hover {
  transform: scale(0.96);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.goal-details-div {
  background-color: rgba(227, 237, 249, 1);
  padding: 15px 10px 0px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.timeoff-details-div:hover {
  transform: scale(0.96);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.timeoff-details-div {
  background-color: rgba(227, 237, 249, 1);
  padding: 15px 10px 10px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.details-div {
  height: 275px;
  overflow-y: auto;
}
.weeklygoal-action-button {
  text-align: -webkit-right;
}
.view-icon-div {
  margin-top: -13px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: green;
  text-align: center;
  margin-bottom: 13px;
}
.download-icon-div {
  margin-top: -13px;
  width: 40px;
  margin-left: 11px;
  height: 40px;
  border-radius: 20%;
  background-color: rgba(37, 61, 144, 1);
  text-align: center;
  margin-bottom: 13px;
}
.module-outer-div {
  margin-bottom: 20px;
}
.employees-action-div {
  display: flex;
  justify-content: end;
}
.approved-bar {
  margin-top: -13px;
  margin-left: -25px;
}

p.approved-text {
  margin-right: 56px;
}
.processing-bar {
  margin-top: -13px;
  margin-left: -25px;
}
p.processing-text {
  margin-right: 56px;
}
.rejected-bar {
  margin-top: -13px;
  margin-left: -25px;
}
p.rejected-text {
  margin-right: 56px;
}
.view-button {
  width: 37%;
  margin: auto;
}
.bar-text {
  text-align: right;
}
p.employee-view-text {
  margin-top: -9px;
  margin-left: 4px;
}
.searchInput-style {
  width: 300px;
  margin-left: -1px;
  height: 50;
  position: relative;
  top: -9px;
  border-radius: 0px 10px 10px 0px;
}
@media only screen and (max-width: 600px) {
  .search-input-with-select {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 1024px) {
  .highfives-module {
    margin-bottom: 20px;
  }
  .kpi-module {
    margin-bottom: 20px;
  }
  .leaves-module {
    margin-bottom: 20px;
  }
  .employees-module {
    margin-bottom: 20px;
  }
  .weekly-goals-module {
    margin-bottom: 20px;
  }
  .search-input {
    margin-left: 20px;
  }
  .bar-text {
    text-align: right;
    margin-bottom: 10px;
  }
  p.view-text {
    margin-top: -9px;
    margin-right: -2px;
  }
}

@media only screen and (min-width: 2560px) {
}
