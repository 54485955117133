.organization-detail-main {
  width: 70%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 2%;
  margin-bottom: 20px;
  color: var(--main-fore-color);
  background-color: var(--main-screen-color);
}

.organization-detail-heading-styling {
  font-weight: 600;
  font-size: 30px;
  color: #121c3e;
}

.updateBtnStyle {
  background-color: #3f861e !important;
  border: none !important;
  padding: 10px 30px 30px 30px !important;
}
