.content-styling {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  overflow: hidden;
}
.ant-layout {
  height: 100vh;
}
Button.chat-btn {
  background-color: #5a189a;
  border: none;
}
Button.chat-btn:hover {
  background-color: #5a189a;
  border: none;
}
Button.chat-btn:focus {
  background-color: #5a189a;
  border: none;
}

.asidebarMenuItems {
  color: black !important;
}
.ant-menu-submenu-arrow::before {
  background: black !important;
}
.ant-menu-submenu-arrow::after {
  background: black !important;
}
.sider {
  background-color: white !important;
  height: 100vh;
  width: 200px;
}
ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.sider-menu {
  background-color: transparent;
  border-right: 1px solid white;
  color: black;
  font-size: 16px;
}
.features-heading {
  padding-left: 10px;
}
.user-description {
  padding-left: 15px;
}

span.anticon.anticon-align-left.trigger {
  font-size: 23px;
  margin-left: 20px;
  margin-top: 22px;
}
.dashboard-user-image {
  width: 80px;
  height: 80px;
}
.dashboard-user-data {
  display: flex;
  padding-top: 20px;
  cursor: pointer;
}
p.dashboard-user-name {
  color: black;
  font-weight: 600;
  margin-top: 12px;
}
p.dashboard-user-name:hover {
  font-size: 16px;
}
p.dashboard-user-designation {
  color: black;
  margin-top: -10px;
  font-size: 12px;
}
.features-heading {
  color: black;
  margin-top: 10px;
  margin-bottom: 0;
}
.image-icon-style {
  width: 20px;
  height: 20px;
}
.user-dummy-image-style {
  width: 60px;
  height: 60px;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: transparent !important;
  border-radius: 10px;
}

span.ant-menu-title-content {
  color: black !important;
}
.sider-menu-logout {
  background-color: transparent;
  border-right: 1px solid white;
  color: black;
  position: absolute;
  bottom: 0;
  font-size: 16px;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}
.asidebar-menu-item:hover {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

.ant-tooltip-inner {
  color: black !important;
  background-color: white !important;
}
ul#rc-menu-uuid-65977-3-7-popup li {
  background: white;
  margin: 0;
}
li.ant-menu-item.ant-menu-item-selected.asidebar-menu-item {
  padding: 0px 0px 0px 21px !important;
}
.ant-menu-submenu-title {
  padding: 0px 0px 0px 21px !important;
}
li.ant-menu-item.asidebar-menu-item {
  padding: 0px 0px 0px 21px !important;
}

.ant-menu-submenu-title:hover {
  font-size: 16px;
  font-weight: 600;
}
