.forgotPasswordMain {
  background-color: white;
  height: 100vh;
  padding-top: 230px;
}
.forgotPassword {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  padding: 40px;
  text-align: center;
}
.forgotPasswordTitle {
  font-size: 20px;
  font-weight: 600;
}
.forgotPasswordDescription {
  font-size: 16px;
  margin-top: 10px;
}

.emailInput {
  margin-bottom: 20px;
  margin-top: 20px;
}
.logoBox {
  display: flex;
  justify-content: center;
}
.sendEmailBtn {
  width: 100%;
  background-color: #121c3e;
  padding-top: 10px;
  padding-bottom: 30px;
  border: none;
  font-weight: bold;
}
.logo {
  font-size: 28px;
  color: #4e3487;
  text-align: center;
  padding-left: 10px;
  font-weight: 600;
  padding-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .forgotPassword {
    width: 80%;
  }
  .forgotPasswordInput {
    width: 80%;
  }
}
