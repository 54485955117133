.view-high-five-calender {
  background-color: white;
  border-radius: 10px;
  padding: 20px 30px 20px 15px;
}
.highfive-icon {
  font-size: 32px;
  margin: 10px 30px 0px 30px;
}
.view-highfive-content {
  justify-content: center;
  display: flex;
}
.comment-div {
  justify-content: center;
  display: flex;
  margin-top: 20px;
  font-size: 20px;
}
.highfive-comment {
  margin-left: 20px;
}
.highfive-users {
  width: 70px;
  height: 70px;
}
.viewHighFive {
  background-color: white;
  border-radius: 10px;
  padding: 50px 20px 110px 20px;
}

.react-datepicker__day.react-datepicker__day--015.react-datepicker__day--highlighted {
  background-color: #121c3e;
}
