.invitation-main {
  width: 50%;
  height: 500px;
  border: 1px solid #000000;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  position: relative;
  z-index: 1;
  color: var(--main-fore-color);
  background-color: var(--main-screen-color);
}

.accept-invite-div {
  background-color: white;
  height: 100vh;
}
.accept-invitation-inner {
  width: 60%;
  margin: auto;
  padding-top: 200px;
}
.accept-invitation-heading {
  font-weight: 600;
}

.create-account-btn {
  width: 100%;
  background-color: #121c3e !important;
  padding-top: 10px !important;
  padding-bottom: 30px !important;
  border: none !important;
  font-weight: bold;
}
