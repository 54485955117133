.team-main {
  width: 70%;
  height: auto;
  margin-top: 2%;
  padding-top: 40px;
  background-color: white;
  padding-bottom: 20px;
}

.team-heading-styling {
  color: #121c3e;
  margin-top: 10px;
  font-weight: 600;
  font-size: 30px;
}

.createTeamBtn {
  background-color: #3f861e !important;
  border: none !important;
  padding: 10px 30px 30px 30px !important;
}

.team-form-styling {
  width: 40%;
  margin-top: 4%;
  margin-left: 20%;
  margin-right: auto;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .team-main {
    width: 100%;
    height: 100%;
    border: 1px solid #000000;
    margin-top: 100px;
  }

  .team-styling {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
}
