.performance-review-heading {
  font-weight: 600;
  font-size: 24px;
}
Button.create-performance-review-button {
  border: 2px solid green;
  border-radius: 10px;
  color: green;
  font-size: 20px;
  height: 50px;
}
.performance-review-listing {
  margin-top: 40px;
}
.performance-review-div {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
p.performance-review-date {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}
.evaluation {
  text-align: right;
}
.performance-evaluation-user {
  width: 80%;
  margin: auto;
  padding-top: 20px;
}
.evaluation-user {
  font-weight: 600;
  font-size: 14px;
}
