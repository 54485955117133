.performanceReview {
  margin-top: 4%;
  margin-bottom: 20px;
}

.view-performance-review {
  width: 80%;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
}
.evaluated-user-name {
  font-size: 24px;
  font-weight: 600;
}
.performanceReviewHeading {
  color: #4e3487;
  text-align: center;
  font-size: 40px;
  font-family: "Exo 2";
  letter-spacing: -3%;
  margin-bottom: 5%;
}
.employeeNameValue {
  text-align: center;
}
.performanceDurationValue {
  text-align: center;
}
.ratingheading {
  color: #646464;
  font-size: 18px;
}
.commentheading {
  color: #646464;
  font-size: 18px;
}
.employee-comment-heading {
  font-size: 18px;
  color: #646464;
  font-weight: 600;
}
.reviewSubmitBtn {
  text-align: center;

  padding-bottom: 40px;
}
.commentSubmitBtn {
  padding-left: 40px;
  padding-right: 40px;
  background-color: green;
  border: none;
  color: white;
}
.commentSubmitBtn:hover {
  padding-left: 40px;
  padding-right: 40px;
  background-color: green;
  border: none;
  color: white;
}

.employeeCommentInput {
  border: none;
  border-bottom: 1px solid #dcdcdc;
  width: 90%;
}
.evaluated-user-info {
  width: 80%;
}
.user-review-emoji {
  text-align: right;
  font-size: 30px;
}
