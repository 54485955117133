.organization-main {
  width: 72%;
  height: auto;
  margin-right: auto;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 20px;
}

.organization-heading-styling {
  color: #121c3e;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 30px;
}
.org-registration-form-content {
  width: 60%;
  margin: auto;
}
.registerBtn {
  background-color: #3f861e !important;
  border: none !important;
  padding: 10px 30px 30px 30px !important;
}
.register-btn-div {
  text-align: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .organization-main {
    width: 100%;
    height: 100%;
    border: 1px solid #000000;
    margin-top: 100px;
  }
  .form-styling {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .registration-main {
    width: 100%;
    height: 100%;
    border: 1px solid #000000;
    margin-top: 100px;
  }
  .form-styling {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
}
