.company-mood-chart-title {
  font-weight: 600;
  font-size: 24px;
}
.company-chart {
  background-color: white;
  padding: 40px;
  border-radius: 20px;
}
.employees-mood-chart-main {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  height: 400px;
  overflow-y: hidden;
  overflow-y: auto;
}
.employee-details-div:hover {
  transform: scale(0.96);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.employee-details-div {
  background-color: rgba(227, 237, 249, 1);
  padding: 15px 10px 10px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
img.employee-image {
  width: 60px;
  height: 60px;
}
