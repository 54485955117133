.highFiveInputStyle {
  width: 100%;
  border: 1px solid black;
  border-radius: 5px;
}
.highFivesListing {
  width: 90%;
  margin: auto;
}
.likesCount {
  font-size: 16px;
  font-weight: 600;
}
.listingDetails {
  margin-top: 40px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}
.createHighFiveBtn {
  border: 2px solid green;
  border-radius: 10px;
  margin-bottom: 20px;
}
.commentBtnStyle {
  border: 2px solid green;
  color: black;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 800;
  margin-top: 65px;
}
.closeComment {
  text-align: right;
  margin-top: -5px;
  margin-bottom: 10px;
}
.ant-modal-close-x {
  display: none !important;
}
.commentValue {
  font-weight: 600;
}
.highFiveComment {
  font-weight: 600;
}
.comment-date {
  margin-top: -7px;
}
.commnetInputBtnDiv {
  margin-top: 20px;
}
.highFiveUserDetails {
  margin-top: 40px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}
.mostAppreciatedHighFives {
  margin-top: 40px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  height: 300px;
  overflow-y: auto;
}
.topHighFives {
  margin-top: 40px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  height: 300px;
  overflow-y: auto;
}
.userDetails {
  text-align: center;
}
.highFiveCreator {
  font-weight: 600;
  padding-top: 10px;
}
.verticalDivider {
  height: 100%;
  width: 2px;
  background-color: black;
}
.receivedHeading {
  font-weight: 600;
}
.givenHeading {
  font-weight: 600;
}
.highFiveCount {
  text-align: right;
}
.highFiveCountAndHeading {
  margin-top: 25px;
}
.highFiveCreatorImage {
  width: 60px;
  height: 60px;
}
.ant-picker.ant-picker-range.highFiveInputStyle {
  border: 1px solid black;
  border-radius: 5px;
}
.highFivesContent {
  background-color: white;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
}
.highFiveDate {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
}
.small-divider {
  height: 1px;
  background-color: black;
}
.smallDividerOne {
  width: 60%;
  margin: auto;
  height: 1px;
  background-color: black;
}
.divider {
  padding: 20px 0px;
}
.viewHighfiveContent {
  width: 60%;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}
.receiveHighFive {
  text-align: center;
}

.sendHighFive {
  text-align: center;
}
.listingHighfiveIcon {
  text-align: center;
  font-size: 40px;
}
.HighFiveUsername {
  font-weight: 600;
  margin-top: 10px;
}
.highFiveLike {
  display: flex;
}
.highFiveCommentModal {
  display: flex;
  margin-left: 40px;
}
.highFiveLikeComment {
  display: flex;
}
.highFiveCommentIcon {
  font-size: 24px;
}

.highFiveLikeIcon {
  font-size: 24px;
}

.highFiveLikeCount {
  font-weight: 600;
  margin-left: 10px;
}
.highFiveCommentCount {
  font-weight: 600;
  margin-left: 10px;
}
.mostAppreciatedHeading {
  font-weight: 600;
  font-size: 18px;
}
.topAppreciatedCount {
  text-align: center;
  padding-top: 15px;
}
.topAppreciatedUserImg {
  text-align: center;
}
.topAppreciatedUsername {
  font-weight: 600;
  margin-top: 5px;
}
.mostAppreciatedDetailsWithCount {
  margin-top: 10px;
}

.highfie-users {
  width: 40px;
  height: 40px;
}
