.registration-heading-styling {
  color: white;
  text-align: center;
  font-size: 36px;
  margin-top: 20px;
  font-family: "system-ui";
  font-weight: 600;
}

.registration-subheading-styling {
  color: #121c3e;
  font-size: 24px;
  font-weight: 600;
  font-family: "system-ui";
}
.register-left {
  background-color: #121c3e;
  height: 100vh;
}

.register-left-content {
  width: 80%;
  margin: auto;
}
.register-right-content {
  width: 70%;
  margin: auto;
  padding-top: 150px;
}

.register-left img {
  width: 100%;
  padding-top: 30px;
  margin: auto;
}
.register-right {
  background-color: white;
  padding: 40px;
  height: 100vh;
}
.alreadyAccountDiv {
  text-align: center;
  margin-top: 20px;
}
.signup-form-button {
  width: 100%;
  background-color: #121c3e;
  padding-top: 10px;
  padding-bottom: 30px;
  border: none;
  font-weight: bold;
}
.signup-form-button:hover {
  width: 100%;
  background-color: #121c3e;
  border: none;
}
.signup-form-button:focus {
  width: 100%;
  background-color: #121c3e;
  border: none;
}
.signup-form-button:active {
  width: 100%;
  background-color: #121c3e;
  border: none;
}
.signInLink {
  color: #121c3e;
  font-weight: 600;
}
.alreadyAccountHeading {
  padding-right: 10px;
}
a.signInLink:hover {
  color: #121c3e;
  font-weight: 600;
}
.existing-user-message {
  text-align: center;
  color: red;
  margin-top: 5px;
}

.ant-form-item-explain-error {
  color: red;
}

.input-error {
  border-color: red !important;
}

.input-error:focus {
  border-color: red !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
}

span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg.signupPassword.input-error:focus {
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2) !important;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-lg.signupEmail.input-error:focus {
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2) !important;
}

@media screen and (max-width: 600px) {
  .register-right-content {
    width: 90%;
    margin: auto;
    padding-top: 100px;
  }
}
