.team-listing-detail-main {
  width: 72%;
  height: auto;
  border: 1px solid #000000;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  color: var(--main-fore-color);
  background-color: var(--main-screen-color);
  padding-bottom: 40px;
}

.team-listing-detail-heading-styling {
  font-weight: 600;
  font-size: 30px;
  color: #121c3e;
}

.team-new-style {
  background-color: white;
  padding: 20px 30px 30px 30px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.team-content {
  text-align: center;
  color: #000000;
}
.team-title-style {
  font-weight: 600;
  color: black !important;
}
.delete-team-icon-style {
  text-align: right;
}
