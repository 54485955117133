.image-upload {
  padding-top: 50px;
  width: 25%;
  margin: auto;
}
.info-section {
  position: absolute;
  width: 100%;
  top: 230px;
}
.firstNameRow {
  margin-top: 60px;
}
.lasttNameRow {
  margin-top: 40px;
}
p.firstNameHeading {
  color: #4e3487;
  font-size: 14px;
  font-weight: 400;
}
p.lastNameHeading {
  color: #4e3487;
  font-size: 14px;
  font-weight: 400;
}
input.designationInput {
  height: 32px;
  width: 214px;
  border: 1px solid #d9d9d9;
}
input.firstNameInput {
  height: 32px;
  width: 214px;
  border: 1px solid #d9d9d9;
}
input.lastNameInput {
  height: 32px;
  width: 214px;
  border: 1px solid #d9d9d9;
}
.lastNameValue {
  color: #4e3487;
  font-size: 14px;
  font-weight: 400;
}
.firstNameValue {
  color: #4e3487;
  font-size: 14px;
  font-weight: 400;
}
button.profileUpdateBtn {
  background: green;
  color: white;
  border: none;
}
.viewProfileTitle {
  font-size: 24px;
  font-weight: 600;
}
.profileUpdateBtnClass {
  width: 20%;
  margin: auto;
}
.profileScreenCard {
  background-color: white;
  width: 70%;
  margin-right: auto;
  padding-bottom: 20px;
}
.imagePreview {
  text-align: center;
  margin-top: 20px;
}
.imagePreviewDummy {
  text-align: center;
  margin-top: 20px;
}
.designationNameHeading {
  color: #4e3487;
}
.designationValue {
  color: #4e3487;
}
.editIcon {
  position: absolute;
  top: 8px;
  left: 172px;
}
button.ant-btn.changeImgBtn {
  margin-top: 10px;
  position: absolute;
  right: 77px;
  top: 49px;
  background: #210d0d3d;
  color: white;
  font-weight: bold;
}

.roundedProfileImg {
  border-radius: 50%;
  height: 150px;
  width: 150px;
  object-fit: contain;
}
.roundedProfileImgDummy {
  border-radius: 50%;
  height: 150px;
  width: 150px;
  object-fit: contain;
}
div#uploadImageDiv {
  position: absolute;
  right: -47px;
}
input.ant-input.designationInput::placeholder {
  color: black;
}
div#userDataForm {
  margin-top: 40px;
}

.image-upload {
  padding-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadImage {
  position: relative;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* YourComponent.css */

.form-section {
  margin: 20px;
}

.form-fields-container {
  max-width: 400px; /* Adjust the max-width as needed */
  margin: auto;
}

.ant-form-item-label {
  width: 120px; /* Adjust the width of labels as needed */
  text-align: right;
  padding-right: 16px; /* Add padding for better spacing */
}

@media only screen and (max-width: 768px) {
  .profileScreenCard {
    width: 100%;
  }
}
