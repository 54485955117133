.okrListings {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  color: var(--main-fore-color);
  background-color: var(--main-screen-color);
  padding-bottom: 40px;
  padding-top: 20px;
}
.ant-table-thead > tr > th {
  background: #e3edf9;
}
.even-row {
  background-color: #e3edf9; /* Set your desired background color here */
}
.okrHeading {
  font-size: 28px;
  font-weight: 700;
  color: #4e3487;
  padding-bottom: 30px;
}
.okrListingsTitle {
  font-size: 18px;
  font-weight: 700;
  padding-left: 20px;
  padding-bottom: 30px;
}
.okrSubListingsRow {
  padding-left: 50px;
}
tr.objectiveTeamsRow {
  display: grid;
  margin-top: 30px;
}
td.objectiveTeamsData {
  line-height: 0.6;
}
th.objectiveTeamsHeading {
  margin-bottom: 15px;
}
.okrListingHeadings {
  font-size: 16px;
  /* padding-left: 30px; */
  font-weight: 600;
  color: #9fa2b4;
}
/* .okrListingsRow{
  border-bottom: 1px solid #DFE0EB;
} */
.listingContentHeadings {
  color: #252733;
  font-size: 14px;
  /* margin-top: -22px; */
  /* padding-left: 30px; */
  font-weight: 600;
}
.listingTargetAchievedHeadings {
  color: #252733;
  font-size: 14px;
  /* margin-top: -22px; */
  padding-left: 10px;
  font-weight: 600;
}
.okrSubListingHeadings {
  font-size: 14px;
  /* padding-left: 30px; */
  font-weight: 600;
  color: #252733;
}
.okrSubListingContent {
  font-size: 18px;
  /* padding-left: 30px; */
  font-weight: 400;
  color: #4e3487;
}
.listingSubtitle {
  color: #c5c7cd;
  font-size: 10px;
  /* margin-top: -10px; */
  /* padding-left: 30px; */
  font-weight: 600;
}

.ant-collapse-borderless {
  background-color: white;
  border: 0;
}
.moreOutlinedIcon {
  /* margin-top: -22px; */
  /* padding-left: 30px; */
  font-size: 22px;
}
.sortingIconHeading {
  display: flex;
  margin-top: 10px;
}
.sortingIcon {
  color: #c5c7cd;
  font-size: 14px;
}
.sortingIconTitle {
  font-size: 14px;
  padding-left: 10px;
  color: #4b506d;
  font-weight: 600;
}
.filterIconHeading {
  display: flex;
  margin-top: 10px;
}
.filterIcon {
  color: #c5c7cd;
  font-size: 14px;
}
.filterIconTitle {
  font-size: 14px;
  padding-left: 10px;
  color: #4b506d;
  font-weight: 600;
}
.paginationRow {
  margin-top: 20px;
}
.rowsCountingTitle {
  font-size: 14px;
  padding-left: 10px;
  color: #4b506d;
  margin-top: 4px;
  margin-right: 20px;
  font-weight: 600;
}
