.relevant-goals-title {
  font-size: 16px;
  font-weight: 600;
}
.weekly-goal-title {
  font-size: 16px;
  font-weight: 600;
}
.duration-div {
  display: flex;
}
.duration-label {
  margin-right: 20px;
  font-size: 16px;
  color: #646464;
}

.details-label {
  margin-right: 20px;
  font-size: 16px;
  color: #646464;
}
#updateAllGoalsBtn {
  background-color: green;
  border: none;
}
.closeWeeklyGoal {
  text-align: end;
  margin-top: -10px;
}
.durations {
  font-size: 16px;
  color: #646464;
}
.relevant-goals {
  font-size: 16px;
  color: #646464;
}
.details {
  font-size: 16px;
  color: #646464;
}
.weekly-goal-edit-icon {
  color: gray !important;
  margin-right: 5px;
}
.weekly-goal-delete-icon {
  color: gray !important;
}

button#updateWeeklyGoalBtn {
  background: green;
  border: none;
}

.closeIconDiv {
  text-align: right;
  position: relative;
  top: 18px;
}

.action-icons {
  text-align: end;
}
.date-picker-style {
  text-align: right;
}

.react-datepicker {
  border: 2px solid #121c3e;
  border-radius: 8px;
  color: white;
}

.react-datepicker__header {
  background-color: #121c3e;
  color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 12px;
}
.react-datepicker__current-month {
  color: white;
}
.react-datepicker__day-name {
  color: white;
}

.react-datepicker__day {
  cursor: pointer;
}

.react-datepicker__day:hover {
  background-color: #121c3e;
  color: #fff;
}

.react-datepicker__day--selected {
  background-color: #121c3e;
  color: #fff;
}

.react-datepicker__day--keyboard-selected {
  background-color: #121c3e;
  color: #fff;
}

.react-datepicker__day--in-range {
  background-color: #121c3e;
  color: #fff;
}

.react-datepicker__day--today {
  font-weight: bold;
}
.checkin-btn {
  text-align: center;
}
Button.checkin-btn-style {
  border: 2px solid #ac8104;
  color: #ac8104;
  border-radius: 5px;
  font-size: 20px;
  height: 50px;
  font-weight: 600;
}

Button.checkin-btn-style:hover {
  border: 2px solid #ac8104;
  color: #ac8104;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
  height: 50px;
}

@media only screen and (max-width: 768px) {
  .date-picker-style {
    text-align: left;
  }
}
