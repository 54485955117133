.timeofRequestOther {
  display: none;
}
.errorBlockContent {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.errorBlockContent p {
  color: red;
  font-size: 18px;
}

.errorBlock {
  width: 60%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 40px;
  border-radius: 5px;
  padding-bottom: 3px;
  z-index: 1;
  color: var(--main-fore-color);
  background-color: var(--main-screen-color);
}
Button.go-to-dashboard-btn {
  background-color: green;
  border: none;
}

.timeoff-request-heading {
  font-weight: 600;
  font-size: 30px;
  color: #121c3e;
}

.time-off-request-main {
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px 0px;
}
.time-off-request-form {
  width: 50%;
  margin: auto;
}
.count-heading-style {
  color: rgba(0, 0, 0, 0.41);
  font-weight: 600;
  font-size: 20px;
}
.timeoff-count-div {
  background-color: white;
  border-radius: 10px;
  padding: 20px 20px 1px 20px;
  text-align: center;
}
p.remainingTimeoffCount {
  font-size: 20px;
  font-weight: 600;
}
Button.timeoffSubmitBtn {
  background-color: green;
  color: white;
  padding: 8px 40px 28px 40px;
}
.timeoff-submit-btn-div {
  text-align: center;
}
