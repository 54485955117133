.create-performance-review {
  background-color: white;
  padding: 40px 20px;
  width: 60%;
  border-radius: 10px;
}

.create-performance-review-heading {
  font-weight: 600;
  font-size: 24px;
}

.select-duration {
  width: 100%;
}

.create-performance-review-btn {
  color: #ac8104;
  border-color: #ac8104;
  font-weight: 600;
  border: 2px solid #ac8104;
}

.performance-review-btn {
  text-align: center;
}
.performance-review-style {
  background-color: white;
  width: 70%;
  margin: auto;
  padding: 40px;
  font-weight: 600;
  font-size: 36px;
  text-align: center;
}
