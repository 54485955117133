.view-timeoff-request {
  background-color: white;
  margin-top: 40px;
  border-radius: 20px;
}
.view-timeoff-request-values {
  width: 50%;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 30px;
}
.timeoff-status-value {
  padding-top: 30px;
}
.timeoff-label {
  font-weight: 600;
}
