.timeoffdashboard {
  margin-top: 6%;
}
.leavestatus {
  width: 90%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  color: var(--main-fore-color);
  background-color: var(--main-screen-color);
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
  border-radius: 10px;
}
.leavetype {
  width: 90%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  color: var(--main-fore-color);
  background-color: var(--main-screen-color);
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
  border-radius: 10px;
}
.tomeoffactivities {
  width: 90%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  color: var(--main-fore-color);
  background-color: var(--main-screen-color);
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
  border-radius: 10px;
  margin-top: 10%;
}
.organizationdetails {
  width: 90%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  color: var(--main-fore-color);
  background-color: #212145;
  margin-top: 10%;
  border-radius: 10px;
  padding-bottom: 20px;
}

.approvedheading {
  font-size: 14px;
  font-weight: 400;
  color: #9191af;
  text-align: center;
}
.rejectedheading {
  font-size: 14px;
  font-weight: 400;
  color: #9191af;
  text-align: center;
}
.pendingheading {
  font-size: 14px;
  font-weight: 400;
  color: #9191af;
  text-align: center;
}
.approvedcount {
  font-size: 30px;
  font-weight: 700;
  color: #212145;
  text-align: center;
}
.totalcount {
  font-size: 30px;
  font-weight: 700;
  color: #212145;
  text-align: center;
}
.sickleavecount {
  font-size: 30px;
  font-weight: 700;
  color: #212145;
  text-align: center;
}
.rejectedcount {
  font-size: 30px;
  font-weight: 700;
  color: #212145;
  text-align: center;
}
.pendingcount {
  font-size: 30px;
  font-weight: 700;
  color: #212145;
  text-align: center;
}
.annualheading {
  font-size: 14px;
  font-weight: 400;
  color: #9191af;
  text-align: center;
}
.vocationalheading {
  font-size: 14px;
  font-weight: 400;
  color: #9191af;
  text-align: center;
}
.emergencyheading {
  font-size: 14px;
  font-weight: 400;
  color: #9191af;
  text-align: center;
}
.otherheading {
  font-size: 14px;
  font-weight: 400;
  color: #9191af;
  text-align: center;
}
.timeoffrequestbtn {
  text-align: center;
  margin-top: 6%;
}
.requestbtnstyle {
  border-radius: 5px;
}
.doticon {
  font-size: 30px;
  text-align: right;
  margin-top: -10px;
  color: #9191af;
}
.activitydoticon {
  font-size: 30px;
  width: 185%;
  margin-top: -10px;
  color: #9191af;
}
.activitytitle {
  font-size: 15px;
  font-weight: 600;
  color: #9191af;
  padding-left: 20px;
}
.activitiesicon {
  width: 50%;
  height: auto;
  background-color: #f1f3f8;
  border-radius: 50%;
  text-align: center;
  margin-left: 20px;
}
.moreicon {
  width: 90%;
  height: auto;
  background-color: #f1f3f8;
  border-radius: 10%;
  text-align: center;
  margin-left: 20px;
  margin-top: 20px;
}
.statusanddate {
  display: flex;
  line-height: normal;
}
.activitydatetime {
  padding-left: 20px;
  color: #c7cfe4;
  font-size: 12px;
  font-weight: 400;
}
.daterange {
  color: #565682;
  font-size: 14px;
  font-weight: 600;
}

.candidatename {
  font-size: 14px;
  font-weight: 600;
  color: #565682;
  text-align: left;
}
.detailsheadingsection {
  width: 80%;
  margin: auto;
  background-color: white;
  border-radius: 8px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  margin-bottom: 40px;
}
.detailscontentsection {
  width: 80%;
  margin: auto;
  background-color: white;
  border-radius: 8px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 120px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 50px;
}
.organizationdetailsheading {
  font-size: 15px;
  font-weight: 600;
  color: #212145;
}
.orgmanagername {
  text-align: left;
  color: #212145;
  font-size: 15px;
  font-weight: 600;
  padding-left: 10px;
}
.orgemployeename {
  text-align: left;
  color: #212145;
  font-size: 15px;
  font-weight: 600;
  padding-left: 10px;
  margin-top: 40px;
}
.employeepost {
  color: #212145;
  font-size: 15px;
  font-weight: 600;
  margin-top: 40px;
}
.managerpost {
  color: #212145;
  font-size: 15px;
  font-weight: 600;
}

.dropdown-style {
  text-align: right;
  padding-right: 30px;
}
.pendingStyle {
  color: #f97078;
}
.onAllTimeOffStyle {
  color: #0e3a64;
}
.approvedStyle {
  color: #c377f0;
}
.rejectedStyle {
  color: #0fb269;
}
.doticonrow {
  text-align: right;
}
.leavetypedoticon {
  text-align: right;
  padding-right: 10px;
}
.postofUser {
  width: 100%;
  height: 500px;
}
.candidateNameDiv {
  width: 100%;
  height: 50px;
}
.candidatePostDiv {
  width: 100%;
  height: 50px;
}
