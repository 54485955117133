.page-not-found {
  width: 60%;
  margin: 100px auto;
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.page-not-found-heading {
  font-size: 66px;
  font-weight: 600;
  text-align: center;
}

.page-not-found-subheading {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-top: -60px;
}
.error {
  font-size: 16px;
  font-weight: 600;
  color: #9b9b9b;
  text-align: center;
}
Button.go-to-dashbaord-btn {
  background-color: green;
  color: white;
}
@media only screen and (max-width: 768px) {
  .page-not-found-heading {
    font-size: 36px;
  }
  .page-not-found {
    width: 90%;
    margin: 10px auto;
  }
  .page-not-found-subheading {
    font-size: 16px;
    margin-top: -10px;
  }
}
