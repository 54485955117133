.team-detail-main {
  width: 72%;
  height: auto;
  background-color: white;
  padding: 20px;
}

.team-detail-heading-styling {
  font-weight: 600;
  font-size: 26px;
}
.view-team-heading-style {
  font-weight: 600;
  font-size: 26px;
  text-align: center;
}

Button.update-team-btn {
  background-color: green;
  border: none;
}
