.viewWeeklygoalyMain {
  width: 100%;
  height: auto;
  padding: 30px 40px 40px 40px;
  margin-top: 2%;
  background-color: #fff;
}

.viewWeeklygoals-heading-styling {
  font-weight: 600;
  font-size: 24px;
}

.addweeklygoals-subheading-styling {
  color: var(--main-fore-color);
  text-align: center;
  margin-top: 1px !important;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
}

.addweeklygoals-form-styling {
  color: var(--main-fore-color);
  width: 40%;
  margin-top: 4%;
  margin-left: 20%;
  margin-right: auto;
}

.ant-form-item-label > label {
  color: var(--main-fore-color);
}
.dynamic-delete-button {
  cursor: pointer;
  position: left;
  margin-right: auto;

  top: 4px;
  font-size: 14px;
  color: var(--main-fore-color);
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: var(--main-fore-color);
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.weeklyGoalButton {
  border: none;
  color: rgba(78, 52, 135, 1);
  border-radius: 5px;
  margin-bottom: 10px;
}
.CheckInButton {
  border: none;
  color: rgba(78, 52, 135, 1);
  border-radius: 5px;
  margin-bottom: 10px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .organization-main {
    width: 100%;
    height: 100%;
    border: 1px solid #000000;
    margin-top: 100px;
  }
  .form-styling {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .registration-main {
    width: 100%;
    height: 100%;
    border: 1px solid #000000;
    margin-top: 100px;
  }
  .form-styling {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
}
