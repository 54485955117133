.create-checkin-main {
  background-color: white;
  padding: 20px 150px 1px 150px;
  width: 60%;
  margin-right: auto;
}
.create-checkin-heading {
  font-weight: 600;
  font-size: 24px;
}
.user-content {
  display: flex;
  justify-content: center;
}
.checkin-weekly-goal-duration {
  text-align: center;
  font-weight: 600;
  margin-top: 10px;
}
.checkin-user-name {
  font-weight: 600;
}
.checkin-user-designation {
  font-weight: 500;
}
.checkin-user-img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}
.checkin-rating-question {
  display: flex;
}
Button.submit-checkin-btn {
  background-color: green;
  border: none;
}
.ratings {
  padding: 0px 30px;
}
.awful,
.great {
  margin-top: 6px;
}
