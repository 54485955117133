.viewcheckInsMain {
  width: 64%;
  height: 500px;
  margin-top: 0%;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  background-color: var(--main-screen-color);
  padding-bottom: 200px;
  padding-top: 60px;
}
.view-checkin-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}
.userImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.userImageDiv {
  text-align: right;
}
.userNamae {
  text-align: left;
  font-weight: 700;
  font-size: 20px;
  padding-left: 20px;
  padding-top: 10px;
}
.userDesignation {
  font-size: 14px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: -20px;
}

.awfulRating {
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
}
.greatRating {
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  text-align: center;
}
.viewCheckInContent {
  width: 60%;
  margin: auto;
}
.checkInsDateRange {
  text-align: center;
  font-weight: 600;
}
.ratingRow {
  display: flex;
}
.ratings {
  margin-left: 10px;
  margin-right: 10px;
}
