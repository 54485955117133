.rating-section {
  background-color: white;
  width: 70%;
  padding: 30px 50px 30px 30px;
}
.icon-wrapper {
  position: relative;
  padding: 0px 30px;
}

.icon-wrapper .anticon {
  position: absolute;
  top: -2px;
  width: 16px;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  line-height: 1;
}

.icon-wrapper .icon-wrapper-active {
  color: rgba(0, 0, 0, 0.45);
}

.icon-wrapper .anticon:first-child {
  left: 0;
}

.icon-wrapper .anticon:last-child {
  right: 0;
}
.ant-slider {
  background-color: #1890ff;
  border-radius: 10px;
}
.ant-slider-step {
  background: #1890ff;
}
.ant-slider-handle {
  width: 25px;
  height: 25px;
  margin-top: -10px;
  border: solid 3px #fff;
  background-color: #1890ff;
}
.ant-slider-handle:focus {
  width: 25px;
  height: 25px;
  margin-top: -10px;
  border: solid 3px #fff;
  background-color: #1890ff;
}
.ant-slider-handle:hover {
  width: 25px;
  height: 25px;
  margin-top: -10px;
  border: solid 3px #fff;
  background-color: #1890ff;
}
Button.submitBtnPerformanceReview {
  background-color: green;
  border: none;
  color: white;
  font-weight: 600;
  padding: 10px 20px 30px 20px;
}
Button.submitBtnPerformanceReview:hover {
  background-color: green;
  border: none;
  color: white;
  font-weight: 600;
  padding: 10px 20px 30px 20px;
}
.reviewSubmitBtn {
  text-align: center;
}
.ant-slider-tooltip .ant-tooltip-inner {
  background: #1890ff !important;
  color: white !important;
  border-radius: 20px !important;
}
.ant-tooltip-arrow-content {
  background-color: #1890ff !important;
}
