#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
.signin-main-div {
  width: 70%;
  margin: auto;
  padding-top: 230px;
}
.sign-in-main {
  background-color: white;
  height: 100vh;
}
.signin-heading-styling {
  color: #121c3e;
  font-size: 24px;
  font-weight: 600;
  font-family: "system-ui";
}
.login-form-button {
  width: 100%;
  background-color: #121c3e !important;
  padding-top: 10px !important;
  padding-bottom: 30px !important;
  border: none;
  font-weight: bold;
}
.login-form-button:hover {
  width: 100%;
  background-color: #121c3e;
  border: none;
}
.login-form-button:focus {
  width: 100%;
  background-color: #121c3e;
  border: none;
}
.login-form-button:active {
  width: 100%;
  background-color: #121c3e;
  border: none;
}
.signUpLink {
  color: #121c3e;
  font-weight: 600;
}
a.signUpLink:hover {
  color: #121c3e;
  font-weight: 600;
}
.error-message {
  text-align: center;
  color: red;
  margin-top: 5px;
}
.loaderLogin {
  z-index: 1;
}
