.company-mood-chart-title {
  font-weight: 600;
  font-size: 24px;
}
.company-chart {
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  margin-bottom: 20px;
}
