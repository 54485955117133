.weekly-goals-title {
  font-size: 24px;
  font-weight: 600;
}
.weekly-goals-card {
  background-color: white;
  text-align: center;
  padding: 30px;
  color: black;
  border-radius: 10px;
}
.weekly-goal-duration {
  font-weight: 600;
}
.add-weekl-goal-btn {
  text-align: right;
}
.addWeeklyGoalBtn {
  background-color: green !important;
  border: none !important;
}
.filters-div {
  background-color: white;
  padding: 30px 40px 20px 40px;
  margin-bottom: 20px;
  border-radius: 10px;
}
