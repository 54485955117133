.createobjectiveTitle {
  font-size: 24px;
  font-weight: 700;
}
.objectivesLable {
  font-size: 16px;
  font-weight: 600;
}
.objectivesContent {
  font-size: 16px;
  font-weight: 400;
}
.title-div {
  margin-top: -10px;
}
.createobjective {
  background-color: white;
  padding: 40px 20px 10px 40px;
  border-radius: 10px;
}
.aspirationalObjectiveInpput {
  width: 550px;
  border: none;
  height: 50px;
  border-radius: 5px;
}
.keyResultsInput {
  border: none;
  height: 50px;
  border-radius: 5px;
  background-color: #e3edf9;
}
input.targetInput {
  background-color: #e3edf9 !important;
  color: black !important;
}
.targetInput {
  border: none;
  height: 50px;
  border-radius: 5px;
}
.targetAchievedInput {
  border: none;
  height: 50px;
  background-color: #e3edf9;
  border-radius: 5px;
}
.updateKeyResultsRow {
  margin-top: 25px;
  margin-bottom: 5px;
}

.updateOKRBtn {
  background-color: #4e3487;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  border: none;
}
.updateObjectiveBtn {
  background: green;
  border: none;
}

.plusIcon {
  border: 1px solid;
  border-radius: 50px;
  font-size: 20px;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #e3edf9;
}
