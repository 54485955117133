:root {
  --main-fore-color: #4e3487;
  --main-background-color: #e5e5e5;
  --main-screen-color: #ffffff;
}
.err-msg {
  color: red;
  font-size: 14px;
  margin-bottom: 0;
}
body {
  background-color: var(--main-background-color) !important;
  height: auto !important;
}

.ant-form-item-label > label {
  color: #121c3e !important;
}
