.addweeklygoals-main {
  width: 80%;
  height: auto;
  margin-top: 2%;
  background-color: white;
}
.addWeeklyGoals-inner {
  width: 70%;
  margin: auto;
  padding-top: 30px;
}
.dynamic-delete-button {
  margin: -15px 0px 8px 8px;
  font-size: 16px;
  color: #999;
}
.addweeklygoals-heading-styling {
  font-weight: 600;
  font-size: 24px;
}
.weeklyGoalActionBtns {
  display: flex;
  justify-content: center;
}
button#addWeeklyGoalBtn {
  margin-left: 20px;
  margin-top: 10px;
}
.ant-btn-dashed {
  color: #cfa308 !important;
  border-color: #cfa308 !important;
}
.addweeklygoals-form-styling {
  color: var(--main-fore-color);
  width: 40%;
  margin-top: 2%;
  margin-left: 20%;
  margin-right: auto;
}

.ant-form-item-label > label {
  color: var(--main-fore-color);
}
.dynamic-delete-button {
  cursor: pointer;
  position: left;
  margin-right: auto;

  top: 4px;
  font-size: 14px;
  color: var(--main-fore-color);
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: var(--main-fore-color);
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
#addWeeklyGoalBtn {
  background-color: #3f861e;
  border: none;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .organization-main {
    width: 100%;
    height: 100%;
    border: 1px solid #000000;
    margin-top: 100px;
  }
  .form-styling {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .registration-main {
    width: 100%;
    height: 100%;
    border: 1px solid #000000;
    margin-top: 100px;
  }
  .form-styling {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
}
