.employee-profile-main {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}
.employee-profile {
  width: 40%;
  margin: auto;
}
.employee-image {
  text-align: center;
}
.employee-name {
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
}
.employee-designation {
  font-size: 16px;
}

.employee-teams {
  font-weight: 600;
}

.employee-image-style {
  width: 150px;
  height: 150px;
}
.list-employee-image-style {
  width: 50px;
  height: 50px;
}
