.changePassword {
  width: 50%;
  height: auto;
  padding: 40px;
  margin-top: 2%;
  background-color: white;
}

.changePasswordTitle {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}
Button.submitPasswordButton {
  background-color: green;
  border: none;
}

@media screen and (max-width: 768px) {
  .changePassword {
    width: 80%;
  }

  .changePasswordInput {
    width: 80%;
  }
}
