.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #ffc20e;
  background: #ffc20e;
  border-radius: 60px;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 10px 60px;
  background: #fff;
  border: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 60px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 30px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black;
  font-size: 20px;
  text-shadow: 0 0 0.25px currentcolor;
}
.ant-tabs-tab:hover {
  color: #90979c;
}
.ant-tabs-tab {
  font-size: 20px;
}
.okr-content-div {
  background: #0f2b8f;
  border-radius: 10px;
  margin-top: 30px;
}
.okr-content-left {
  width: 60%;
  margin: auto;
}

p.stay-updated {
  color: white;
  font-size: 47px;
  padding: 50px 0px;
}

span.employee-okr {
  color: #ffc20e;
}
img.okr-right-img {
  width: 90%;
}
.hidden {
  display: none;
}
.target-setup-main-div {
  background: white;
  padding: 20px;
  border-radius: 10px;
}
.input-style {
  border-radius: 5px;
  background: #e3edf9;
  border: 1px solid #e3edf9;
  width: 100%;
}
.date-input-style {
  border-radius: 5px !important;
  background: #e3edf9 !important;
  border: 1px solid #e3edf9 !important;
  width: 100%;
}
.okr-label-style {
  color: #646464;
  font-size: 16px;
  margin-bottom: 5px;
}
.ant-select.ant-select-lg.select-input-style.ant-select-multiple.ant-select-allow-clear.ant-select-show-search
  .ant-select-selector {
  border-radius: 5px;
  background: #e3edf9;
  border: 1px solid #e3edf9;
  width: 100%;
}
.submit-okr-button {
  background-color: green;
  color: white;
  border-radius: 5px;
  padding: 10px 50px 30px 50px;
  font-weight: 600;
}
.target-table {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
}
.export-and-filter {
  justify-content: end;
  display: flex;
}
.manage-targets {
  font-size: 20px;
  font-weight: 600;
}
.table-heading {
  font-weight: 600;
  text-align: center;
}
.headings-row {
  background-color: #e3edf9;
  padding-top: 20px;
  border-radius: 5px;
}
.export-btn {
  background-color: green;
  border: 1px solid green;
  border-radius: 10px;
}
.table-data {
  text-align: center;
}
.table-data-row {
  padding-top: 40px;
}
.table-data-row-even {
  padding-top: 20px;
  background-color: #e3edf9;
  margin-top: 20px;
  border-radius: 5px;
}
.action-btn {
  background-color: #253d90;
  border: 1px solid #253d90;
  border-radius: 10px;
}
.filter-icon-div {
  margin-right: 11px;
  margin-top: 9px;
}
.more-key-results {
  margin-top: 20px;
}
