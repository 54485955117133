.createHighFive {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 10px;
  padding-bottom: 40px;
  padding-top: 20px;
}
.highFivesTitle {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 30px;
}
.highFiveFrom {
  font-size: 16px;
  font-weight: 600;
}
.highFiveFromValue {
  color: rgba(98, 98, 99, 1);
  font-size: 14px;
  font-weight: 600;
}
.highFiveTo {
  font-size: 16px;
  font-weight: 600;
}
.highFiveComment {
  font-size: 16px;
  font-weight: 600;
  padding-top: 17px;
}
.hiveFivePostBtn {
  margin-top: 10px;
  background-color: #3f861e;
  color: white;
  font-size: 14px;
  font-weight: 800;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.cretaeHighFiveFormDiv {
  width: 70%;
  margin: auto;
}
.highFiveSearchInput {
  width: 100%;
}
.giveHighFiveUser {
  font-weight: 600;
}
.high-five-employee-status {
  display: flex;
}
.check-status {
  justify-content: right;
}
@media only screen and (max-width: 768px) {
  .createHighFive {
    width: 100%;
  }
}
