.highFivesDashboardTitle {
  font-size: 26px;
  font-weight: 700;
  color: #4e3487;
  margin-top: 5%;
}
.highFivesDashboardFilter {
  font-size: 16px;
  font-weight: 600;
  color: #4e3487;
}
.CommentPersonInfo {
  display: flex;
  width: 100%;
  margin-top: 20px;
}
.commentAndDate {
  margin-left: 20px;
}
.highFivesDashboardFilters {
  width: 100%;
  height: auto;
  border: 2px solid rgb(221, 220, 220);
  margin-left: auto;
  margin-right: auto;
  margin-top: 0%;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  color: var(--main-fore-color);
  background-color: var(--main-screen-color);
  padding-bottom: 40px;
  padding-top: 20px;
}
.peopleHeading {
  font-size: 14px;
  font-weight: 600;
  color: #4e3487;
}
.typeHeading {
  font-size: 14px;
  font-weight: 600;
  color: #4e3487;
}
.timePeriodHeading {
  font-size: 14px;
  font-weight: 600;
  color: #4e3487;
}
.peoplesFilter {
  padding-left: 20px;
}
.createHighFivesSection {
  width: 100%;
  height: auto;
  border: 2px solid rgb(221, 220, 220);
  margin-right: auto;
  margin-top: 0%;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  color: var(--main-fore-color);
  background-color: var(--main-screen-color);
  padding-bottom: 40px;
  padding-top: 20px;
}
.createHighFiveHeading {
  font-size: 22px;
  font-weight: 600;
  color: #4e3487;
}
.createHighFiveContent {
  font-size: 16px;
  font-weight: 600;
  color: #4e3487;
}
.createHighFiveButton {
  font-size: 14px;
  font-weight: 800;
  background-color: #4e3487;
  color: white;
}
.giveHighFiveDiv {
  padding-left: 40px;
  padding-right: 20px;
}
.highFivesListSection {
  width: 100%;
  height: 570px;
  border: 2px solid rgb(221, 220, 220);
  margin-right: auto;
  margin-top: 8%;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  color: var(--main-fore-color);
  background-color: var(--main-screen-color);
  overflow-y: auto;
}
.userOneImag {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-left: 10px;
}
.userImag {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.givenHighFiveTitle {
  font-size: 16px;
  font-weight: 600;
}
.givenHighFiveContent {
  font-size: 14px;
  font-weight: 600;
}
.likeIcon {
  font-size: 22px;
  color: black;
}
.commentIcon {
  font-size: 22px;
  color: black;
}

.commentsCount {
  font-size: 16px;
  font-weight: 600;
}
.dottedLine {
  padding-top: 20px;
  border-bottom: thin rgba(0, 0, 0, 0.4) dotted;
  width: 95%;
  margin: auto;
}
.GivenHighFiveList {
  margin-top: 20px;
}

.userImgSentReceivedSection {
  width: 70px;
  height: 70px;
  border-radius: 50px;
}
.givenSentHighFivesSection {
  width: 100%;
  height: auto;
  border: 2px solid rgb(221, 220, 220);
  margin-right: auto;
  margin-top: 0%;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  color: var(--main-fore-color);
  background-color: var(--main-screen-color);
  padding-bottom: 20px;
  padding-top: 20px;
}
.userImageWhoGiveHighFive {
  text-align: center;
}
.userNameSentReceivedSection {
  font: 16;
  font-weight: 600;
  padding-top: 20px;
}
.userdesignationSentReceivedSection {
  font-size: 10px;
  font-weight: 600;
}
.horizontalLine {
  font-size: 20px;
  text-align: center;
}
.vertical {
  border-left: 2px solid #cccccc;
  height: 40px;
  position: absolute;
  left: 50%;
}
.highFiveGiveStyle {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}
.givenHighFiveCount {
  text-align: center;
  margin-top: -10px;
  font-size: 12px;
  font-weight: 400;
}
.highFiveSentStyle {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}
.sentHighFiveCount {
  text-align: center;
  margin-top: -10px;
  font-size: 12px;
  font-weight: 400;
}
.mostAppreciatedSection {
  width: 100%;
  height: auto;
  border: 2px solid rgb(221, 220, 220);
  margin-right: auto;
  margin-top: 0%;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  color: var(--main-fore-color);
  background-color: var(--main-screen-color);
  padding-bottom: 20px;
  padding-top: 20px;
}
.mostAppreciatedTitle {
  font-size: 16px;
  font-weight: 600;
  padding-left: 25px;
}
.mostAppreciatedUserImg {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
.mostAppreciatedUserName {
  font-size: 10px;
  font-weight: 600;
  padding-top: 10px;
}
.mostAppreciatedSentHighFive {
  font-size: 12px;
  font-weight: 400;
  padding-top: 10px;
}
.mostAppreciatedRowStyle {
  margin-bottom: 15px;
}
.appreciatedScrollBar {
  height: 200px;
  overflow-y: auto;
}
.topHighFiversSection {
  width: 100%;
  height: auto;
  border: 2px solid rgb(221, 220, 220);
  margin-right: auto;
  margin-top: 0%;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  color: var(--main-fore-color);
  background-color: var(--main-screen-color);
  padding-bottom: 20px;
  padding-top: 20px;
}
.topHighFiversTitle {
  font-size: 16px;
  font-weight: 600;
  padding-left: 25px;
}
.topHighFiversScrollBar {
  height: 200px;
  overflow-y: auto;
}

.topHighFiversRowStyle {
  margin-bottom: 15px;
}
.topHighFiversUserImg {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
.topHighFiversUserName {
  font-size: 10px;
  font-weight: 600;
  padding-top: 10px;
}
.topHighFiversSentHighFive {
  font-size: 12px;
  font-weight: 400;
  padding-top: 10px;
}
.highFiversListScrollBar {
  height: 517px;
  overflow-y: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.currentListDate {
  width: 100px;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 1);
  text-align: center;
  border-radius: 50%;
  margin: auto;
  margin-top: -20px;
}
.currentListDateMonth {
  font-size: 12px;
  font-weight: 600;
  padding-top: 15px;
}
.commentedUsername {
  font-size: 14px;
  font-weight: 600;
  color: white;
}
.commentedDate {
  font-size: 12px;
  font-weight: 400;
  margin-top: -8px;
  color: white;
}
.commentContent {
  width: 100%;
  height: 240px;
  overflow-x: hidden;
}
.commentTextArea {
  margin-top: 60px;
  width: 238%;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  display: block;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}
.ant-modal-close-icon {
  color: rgba(78, 52, 135, 1);
  display: none;
}
.likedUsername {
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
  margin-left: 20px;
  color: white;
}
.likedPersonInfo {
  margin-top: 10px;
}
