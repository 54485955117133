.reset-password-main {
  background-color: white;
  height: 100vh;
}
.reset-password-form {
  width: 70%;
  margin: auto;
  padding-top: 250px;
}
.reset-password-title {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 20px;
}
Button.submitNewButton {
  background-color: #121c3e;
  border: none;
  width: 100%;
  padding: 10px 0px 30px 0px;
}
@media only screen and (max-width: 600px) {
  .reset-password-form {
    width: 90%;
  }
}
