.otp {
  width: 85%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  padding: 40px;
  text-align: center;
}
.otp-right {
  background-color: white;
  height: 100vh;
  padding-top: 200px;
}
.otpTitle {
  font-size: 24px;
  font-weight: 600;
}
.otpDescription {
  font-size: 16px;
  margin-top: 10px;
}
.otpInputs {
  width: 55%;
  margin: auto;
  display: flex;
  padding-top: 20px;
}
.otpInput {
  margin: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.resend-code {
  width: 50%;
  margin: auto;
}
.submitOtpButton {
  width: 100%;
  background-color: #121c3e;
  border: none;
}
span.count {
  font-size: 14px;
  margin-left: 5px;
}
.resend-code-div {
  display: flex;
  justify-content: center;
}
.timer {
  justify-content: center;
}
.expire-otp {
  margin-bottom: 10px;
}

.time-style {
  color: #1890ff;
  font-weight: 600;
}
