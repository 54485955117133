.listing-section {
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  padding-top: 20px;
}
.requestedIcon {
  color: #d5d916;
  font-size: 40px;
}
.rejectedIcon {
  color: #ff0000;
  font-size: 40px;
}
.approvedIcon {
  color: #0fb269;
  font-size: 40px;
}
.filters-dropdown-style {
  margin-top: 40px;
  text-align: right;
}
.requestedLeavestatus {
  color: #d5d916;
  font-size: 12px;
  font-weight: 400;
}
.rejectedLeavestatus {
  color: red;
  font-size: 12px;
  font-weight: 400;
}
.approvedLeavestatus {
  color: green;
  font-size: 12px;
  font-weight: 400;
}
.empty-timeoffs-listing {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 20px 20px 20px;
}
